<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-29 15:20:34
 * @ Description: Component displaying the public individual profile experience tab.
 -->

<template>
    <v-row>
        <v-col
            cols="12"
            style="position: relative;"
        >
            <!-- Card View -->
            <v-row
                v-if="selectedView === 'card'"
            >
                <!-- No Experience -->
                <v-col
                    v-if="experiences === null || experiences.length === 0"
                    class="os-12-r text-center"
                    style="color: rgba(0, 0, 0, 0.38);"
                >
                    No experience added
                </v-col>
                <!-- No Experience -->

                <!-- Experience -->
                <v-col
                    cols="12"
                    md="4"
                    lg="3"
                    v-for="(item, index) in experiences"
                    :key="index"
                    v-else
                >
                    <v-card
                        height="170px"
                        width="100%"
                        max-width="280px"
                        class="rounded-lg pa-3 mx-auto"
                    >
                        <v-row
                            align="center"
                        >
                            <v-col
                                cols="12"
                                class="pb-0"
                            >
                                <v-icon
                                    color="#2E567A"
                                >
                                    {{ mdiBriefcaseOutline }}
                                </v-icon>
                            </v-col>
                            <v-col
                                class="os-16-sb text-center pt-0 text-truncate"
                                cols="12"
                            >
                                {{ item.role }}
                                <br />
                                <span class="os-14-r">({{ item.experience_level.name }})</span>
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0"
                            >
                                <v-divider />
                            </v-col>
                            <v-col
                                class="os-12-sb text-center"
                                style="color: #2E567A; line-height: 1;"
                                cols="5"
                            >
                                {{ item.employment_start }}
                                <br /> -
                                <br /> <span v-if="item.employment_end">{{ item.employment_end }}</span><span v-else>Present</span>
                            </v-col>
                            <v-col
                                class="os-10-r text-right text-truncate"
                                style="color: #8F9BB3;"
                                align-self="center"
                                cols="7"
                            >
                                {{ item.name }}
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <!-- Experience -->
            </v-row>
            <!-- Card View -->

            <!-- List View -->
            <v-row
                v-if="selectedView === 'list'"
            >
                <!-- Search -->
                <v-col
                    cols="12"
                    class="pb-0 pt-10"
                >
                    <div
                        class="os-17-sb px-3"
                        style="background-color: white; height: 60px; display: flex; align-items: center;"
                    >
                        All Experience
                        <v-spacer />
                        <div
                            style="width: 275px;"
                        >
                            <v-text-field
                                dense
                                hide-details
                                outlined
                                color="#2E567A"
                                placeholder="Search..."
                                class="os-13-r"
                                background-color="white"
                                style="width: 275px;"
                                v-model="search"
                            >
                                <template v-slot:append>
                                    <v-icon
                                        color="#C5CEE0"
                                        class="ma-0"
                                    >
                                        {{ mdiMagnify }}
                                    </v-icon>
                                </template>
                            </v-text-field>
                        </div>
                    </div>
                </v-col>
                <!-- Search -->

                <!-- Experience -->
                <v-col
                    cols="12"
                    class="pt-0"
                >
                    <v-data-table
                        :headers="experienceTableHeaders"
                        :items="experiences"
                        item-key="id"
                        hide-default-footer
                        class="experience-table"
                        no-data-text="No experience added"
                        :no-results-text="noResultsText"
                        :search="search"
                    >
                        <template v-slot:[`item.role`]="{ item }">
                            {{ item.role }}
                        </template>
                        <template v-slot:[`item.experience_level`]="{ item }">
                            {{ item.experience_level.name }}
                        </template>
                        <template v-slot:[`item.name`]="{ item }">
                            {{ item.name }}
                        </template>
                        <template v-slot:[`item.employment_start`]="{ item }">
                            {{ item.employment_start }}
                        </template>
                        <template v-slot:[`item.employment_end`]="{ item }">
                            <span v-if="item.employment_end">{{ item.employment_end }}</span>
                            <span v-else>-</span>
                        </template>
                    </v-data-table>
                </v-col>
                <!-- Experience -->
            </v-row>
            <!-- List View -->
        </v-col>
    </v-row>
</template>
<script>
    import { IndividualProfileExperienceController } from '@/logic/controller/index.controller.js'
    import { mdiMagnify, mdiBriefcaseOutline } from '@mdi/js'

    export default {
        name: 'ProfilePublicIndividualExperienceTabComponent.vue',

        props: {
            selectedView: {
                type: String,
                required: true
            }
        },

        computed: {
            noResultsText () {
                return 'No results found for: "' + this.search + '"'
            }
        },

        data () {
            return {
                mdiMagnify,
                mdiBriefcaseOutline,
                experienceTableHeaders: [
                    {
                        text: 'Role',
                        value: 'role'
                    },
                    {
                        text: 'Experience Level',
                        value: 'experience_level.name'
                    },
                    {
                        text: 'Employer',
                        value: 'name'
                    },
                    {
                        text: 'Employment Start Date',
                        value: 'employment_start'
                    },
                    {
                        text: 'Employment End Date',
                        value: 'employment_end'
                    }
                ],
                experiences: [],
                individualProfileExperienceController: null,
                search: ''
            }
        },

        beforeMount () {
            this.individualProfileExperienceController = new IndividualProfileExperienceController()
        },

        async mounted () {
            await this.getProfileExperience()
        },

        methods: {
            async getProfileExperience () {
                try {
                    this.experiences = await this.individualProfileExperienceController.retrieve(this.$route.params.id)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            }
        }
    }
</script>
<style scoped>
    >>> .v-btn:not(.v-btn--round).v-size--default {
        min-width: 0;
    }

    >>> .v-list-item {
        min-height: 0;
        height: 30px;
    }

    >>> .experience-table th {
        font-size: 15px !important;
        font-weight: 600 !important;
        color: #8F9BB3 !important;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
        height: 60px !important;
        padding: 0px !important;
        padding-left: 12px !important;
    }

    >>> .experience-table td {
        font-size: 12px !important;
        font-weight: normal !important;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
        height: 60px !important;
        padding: 0px !important;
        padding-left: 12px !important;
    }

    >>> .experience-table thead {
        background-color: #EFF4FA !important;
    }

    >>> div.v-input__append-inner {
        margin-top: 5px !important;
    }

    .radio-text /deep/ label {
        color: black !important;
        font-size: 12px !important;
        font-weight: normal !important;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
    }
</style>
